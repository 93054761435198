import React, {Fragment} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import useIosInstallPrompt from "../hooks/useIosInstallPrompt";
import useWebInstallPrompt from "../hooks/useWebInstallPrompt";
import ShareIcon from "./ShareIcon";
import {isIOS,isAndroid,isChrome, isIOS13} from "react-device-detect";

const showInstallOptions = false;

function getModalStyle() {
    const top = 25;
    //const left = 50;

    return {
        top: `${top}%`,
        margin:'auto'
        /*left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,*/
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        resize: true,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    shareIcon: {
        width: '20px',
        marginLeft: '10px',
        marginRight: '10px'
    }
}));

export default function InstallApp({open, setInstalled, appInstalled}) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    window.addEventListener('appinstalled', (evt) => {
        if (typeof evt === 'undefined') {
            localStorage.removeItem('cbapp_installed');
        }
        setInstalled();
    });

    const handleClose = () => {
        //do stuff on close
    };

    let errorText;
    if (isIOS13) {
        errorText = '你用的操作系統 iOS 太舊. 要使用 eCalbible app 你的操作系統必須是 iOS14 或以上';
    }else if (isIOS) {
        errorText = '聖經分析排版本電子研讀版需要 Safari 瀏覽器才能安裝在蘋果手機/平版/電腦上 (iPhone/iPad)';
    } else if (isAndroid) {
        errorText = '聖經分析排版本電子研讀版只能用 Chrome 瀏覽器安裝在安卓Android 手機/平版/電腦上';
    } else if (!isChrome) {
        errorText = '聖經分析排版本電子研讀版應用軟體若要安裝在 PC或Mac電腦上, 必須用 Chrome 谷歌瀏覽器來安裝';
    } else {
        errorText = 'An unknown error occurred loading the Calbible app installer.';
    }

    return (
        <Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h3 id="simple-modal-title" style={{marginTop: '-13px',marginLeft: '-30px',marginRight: '-30px',background: '#efefef'}}>&nbsp;&nbsp;eCalBible App</h3>
                    <Box textAlign='center'>
                        <img
                            className="mx-auto"
                            style={{
                                /*borderTopRightRadius: '50%',
                                borderTopLeftRadius: '50%',*/
                                backgroundColor: '#fff',
                                /*marginTop: '-20px'*/
                            }}
                            src="icon-72x72.png"
                            alt="Icon"
                        />
                    </Box>
                    {!showInstallOptions && !isIOS && !webInstallPrompt && !isChrome ?
                        <p id="simple-modal-description">
                            <strong>不好意思</strong><br/><br/>

                            {errorText}
                        </p>
                        : iosInstallPrompt ?
                            <Fragment>
                                <p id="simple-modal-description">
                                    安裝於蘋果手機/平版/電腦步驟:
                                </p>

                                <p>
                                    點擊
                                    <ShareIcon className={classes.shareIcon} modern={true}/> 在你的屏幕下方,
                                    然後點擊<br/>"加在主屏幕上"<br/><br/>
                                    在你的右上方, 點擊  "加" 按鈕來確認你要安裝這應用軟體
                                    <br/><br/>
                                    聖經分析排版本電子研讀版的圖標 (如上) 會顯示出來, 說明安裝成功
                                </p>

                                {/*<div className="d-flex justify-content-center">
                                    <Button onClick={handleIOSInstallDeclined}>Close</Button>
                                </div>*/}
                            </Fragment>
                           :
                            <div className="d-flex justify-content-around">
                                <p id="simple-modal-description">
                                    安裝於安卓手機/平版/電腦步驟<br/><br/>
                                    點擊安裝 "安裝"<br/>
                                    點擊來完成安裝
                                </p>
                                <Box textAlign='center'>
                                    {appInstalled ?
                                        <Button variant="contained" disabled={true}>
                                            已安裝
                                        </Button>
                                        :
                                        <Button variant="contained" onClick={handleWebInstallAccepted}>
                                            安裝
                                        </Button>
                                    }
                                </Box>
                                {/*<Button onClick={handleWebInstallDeclined}>Close</Button>*/}
                            </div>
                    }
                </div>
            </Modal>
        </Fragment>
    );
}