import useShouldShowPrompt from './useShouldShowPrompt';
import {isIOS} from "react-device-detect";

const iosInstallPromptedAt = 'iosInstallPromptedAt';

const useIosInstallPrompt = () => {
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(iosInstallPromptedAt);

    return [isIOS /*&& userShouldBePromptedToInstall*/, handleUserSeeingInstallPrompt];
};
export default useIosInstallPrompt;