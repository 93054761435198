import { createStore } from 'redux';

// Centralized application state
// For more information visit http://redux.js.org/
const store = createStore((state = { activated: true, checking_activation: false, activation_modal: false, activation_message: null }, action) => {
    // TODO: Add action handlers (aka "reducers")
    switch (action.type) {
        case 'SET_ACTIVATED':
            return { ...state, activated: action.payload };
        case 'SET_CHECKING_ACTIVATION':
            return { ...state, checking_activation: action.payload };
        case 'SET_ACTIVATION_MODAL':
            return { ...state, activation_modal: action.payload };
        case 'SET_ACTIVATION_MSG':
            return { ...state, activation_message: action.payload };
        default:
            return state;
    }
});

export default store;