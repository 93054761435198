import React, {Fragment} from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: '16px',
        flex: 1,
    },
}));

const handleResultClick = (result, history, onClose) => {
    onClose('search result click');
    history.push(`/${result.abbrev}/${result.chapt}/${result.verse}`);
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchResultsDialog({resultsOpen, searchResults, onSearchResultsClose}) {
    const classes = useStyles();
    const history = useHistory();

    const query = typeof searchResults[0] !== 'undefined' ? searchResults[0].query : '';
    return (

        <Dialog fullScreen open={resultsOpen} onClose={()=> {onSearchResultsClose('Dialog')}} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={()=> {onSearchResultsClose('Close Icon')}} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        搜索結果: {query}
                    </Typography>
                </Toolbar>
            </AppBar>
            <List>
                {searchResults.map((result, i) => {
                    const verseText = result.versetext.replaceAll('<u>', '').replaceAll('</u>', '');
                    return (
                    <Fragment>
                        <ListItem key={i} style={{marginTop: i === 0 ? '60px' : '6px'}} button>
                            <ListItemText primary={result.bookname + ' ' + result.chapt + ':' + result.verse} secondary={verseText} onClick={() => handleResultClick(result, history, onSearchResultsClose)} />
                        </ListItem>
                        <Divider />
                    </Fragment>
                    );
                })}
            </List>
        </Dialog>
    );
}