import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './api/redux/store';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { set } from 'idb-keyval';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './css/index.css';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const theme = createTheme();

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline key="css" />
    <Provider store={store}>
        <BrowserRouter key="router">
          <Switch>
            <Route path='/' component={App} />
          </Switch>
        </BrowserRouter>
    </Provider>
  </MuiThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
serviceWorker.register({ onUpdate: (registration) => {
    set('CBUPDATE', 1);
}});
