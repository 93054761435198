import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import PdfViewer from "./pdfviewer/PdfViewer";
import Appendix from "./Appendix";
import Bible from "./Bible";
import Maps from "./Maps";

export default function Router({state, activated, textSize}) {
    return (
        <Switch>
            <Route path='/gospel-intro'><PdfViewer filecode="gospel-intro"/></Route>
            <Route path='/preface'><PdfViewer filecode="preface" /></Route>
            <Route path='/instruction'><PdfViewer filecode="instruction"/></Route>
            <Route path='/manual'><PdfViewer filecode="manual"/></Route>
            <Route path='/table-of-contents'><PdfViewer filecode="toc"/></Route>
            <Route path='/ot-intro'><PdfViewer filecode="ot-intro"/></Route>
            <Route path='/nt-intro'><PdfViewer filecode="nt-intro"/></Route>
            <Route path='/copyright'><PdfViewer filecode="copyright"/></Route>
            <Route path='/appendix'><Appendix/></Route>
            <Route path='/maps'><Maps/></Route>
            <Route path='/map-time-patriarchs'><PdfViewer filecode="map-time-patriarchs"/></Route>
            <Route path='/map-route-exodus'><PdfViewer filecode="map-route-exodus"/></Route>
            <Route path='/map-division-canaan'><PdfViewer filecode="map-division-canaan"/></Route>
            <Route path='/map-time-judges'><PdfViewer filecode="map-time-judges"/></Route>
            <Route path='/map-united-israel'><PdfViewer filecode="map-united-israel"/></Route>
            <Route path='/map-babylonian-empire'><PdfViewer filecode="map-babylonian-empire"/></Route>
            <Route path='/map-persian-empire'><PdfViewer filecode="map-persian-empire"/></Route>
            <Route path='/map-greek-empire'><PdfViewer filecode="map-greek-empire"/></Route>
            <Route path='/map-roman-empire'><PdfViewer filecode="map-roman-empire"/></Route>
            <Route path='/map-palestine-jesus-time'><PdfViewer filecode="map-palestine-jesus-time"/></Route>
            <Route path='/map-jerusalem-new-testament'><PdfViewer filecode="map-jerusalem-new-testament"/></Route>
            <Route path='/map-paul-first-mission'><PdfViewer filecode="map-paul-first-mission"/></Route>
            <Route path='/map-paul-second-mission'><PdfViewer filecode="map-paul-second-mission"/></Route>
            <Route path='/map-paul-third-mission'><PdfViewer filecode="map-paul-third-mission"/></Route>
            <Route path='/map-paul-rome-journey'><PdfViewer filecode="map-paul-rome-journey"/></Route>
            <Route path='/analytical-layout'><PdfViewer filecode="analytical-layout"/></Route>
            <Route path='/hermaneutics'><PdfViewer filecode="hermaneutics"/></Route>
            <Route path='/preaching'><PdfViewer filecode="preaching"/></Route>
            <Route path='/covenants'><PdfViewer filecode="covenants"/></Route>
            <Route path='/doctrines'><PdfViewer filecode="doctrines"/></Route>
            <Route path='/messiah-prophecies'><PdfViewer filecode="messiah-prophecies"/></Route>
            <Route path='/life-of-christ'><PdfViewer filecode="life-of-christ"/></Route>
            <Route path='/miracles-of-jesus'><PdfViewer filecode="miracles-of-jesus"/></Route>
            <Route path='/parables-of-jesus'><PdfViewer filecode="parables-of-jesus"/></Route>
            <Route path='/bible-theme-chart'><PdfViewer filecode="bible-theme-chart"/></Route>
            <Route path='/festivals'><PdfViewer filecode="festivals"/></Route>
            <Route path='/jewish-calendar'><PdfViewer filecode="jewish-calendar"/></Route>
            <Route path='/kings-israel'><PdfViewer filecode="kings-israel"/></Route>
            <Route path='/israel-captivity'><PdfViewer filecode="israel-captivity"/></Route>
            <Route path='/paul-missionary-journey'><PdfViewer filecode="paul-missionary-journey"/></Route>
            <Route path='/daniel-structure'><PdfViewer filecode="daniel-structure"/></Route>
            <Route path='/end-time-events'><PdfViewer filecode="end-time-events"/></Route>
            <Route path='/scripture-year-reading'><PdfViewer filecode="scripture-year-reading"/></Route>
            <Route path='/scripture-measurements'><PdfViewer filecode="scripture-measurements"/></Route>
            <Route path='/difficult-words'><PdfViewer filecode="difficult-words"/></Route>

            {/*<Route exact path='/intro-outline/:book/:chapter' component={} />*/}
            <Route exact path='/:book' component={props => <Bible activated={activated} book={props.match.params.book} chapter={1} contents={state.contents} textsize={textSize} screenwidth={state.screenWidth} {...props} />} />
            <Route exact path='/:book/:chapter' component={props => <Bible activated={activated} book={props.match.params.book} chapter={activated ? props.match.params.chapter : 1} contents={state.contents} textsize={textSize} screenwidth={state.screenWidth} {...props} />} />
            <Route exact path='/:book/:chapter/:verse' component={props => <Bible activated={activated} book={props.match.params.book} chapter={activated ? props.match.params.chapter : 1} verse={Number(props.match.params.verse)} contents={state.contents} textsize={textSize} screenwidth={state.screenWidth} {...props} />} />
            <Route path='/' render={() => <Redirect to="/gn" />} />
        </Switch>
    );

}