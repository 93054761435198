import React from 'react';
import {Link} from 'react-router-dom';
import './listview.scss';

const ListView = ({title, items, numbers}) => {

    if (typeof numbers === 'undefined') { numbers = false; }
    let c = 1;
    return (
        <div id="toc_container">
            <p className="toc_title">{title}</p>
            <ul className="toc_list">
                {items.map((item, i) => {
                    if (typeof item.setcnt !== 'undefined') {
                        c = item.setcnt
                        console.log('Changing count to '+item.setcnt);
                    }
                    console.log('c = '+c);
                    const numlabel = typeof item.number !== 'undefined' ? item.number : c;
                    console.log('numlabel = '+numlabel);
                    const itemtext = numbers ? numlabel + '. ' +item.name : item.name;
                    c++;
                    return (
                        <li key={i}><Link to={item.link}>{itemtext}</Link></li>
                    )
                })}
            </ul>
        </div>
    );
};
export default ListView;