import React from 'react';

const pdfFetcher = {

    getCDNURL: () => {
       return 'https://app.calbible.com/assets/pdfs/'
    },

    importAll: (r) => {
        let pdfs = {};
        r.keys().map((item, index) => { pdfs[item.replace('./', '')] = r(item); });
        return pdfs;
    },

    getFileURLFromCode: (filecode) => {
        let fileurl = null;
        if (typeof filecode !== 'undefined') {

            if (filecode.includes('/')) {
                const fcarr = filecode.split('/');
                if (fcarr.includes('chart')) {
                    //1ch-2_study_II_V4.pdf
                    fileurl = pdfFetcher.getCDNURL()+'03_Horizontal_Charts/'+fcarr[0]+'-2_study_II_V4.pdf';
                } else if (fcarr.includes('outline')) {
                    //1tm-1_study_II_V4.pdf;
                    fileurl = pdfFetcher.getCDNURL()+'02_eCalBible_Introduction_pdf/'+fcarr[0]+'-1_study_II_V4.pdf';
                }
            } else {

                switch (filecode) {
                    case 'preface':
                        fileurl = pdfFetcher.getCDNURL()+'01_Preface/03_Preface_V6.pdf';
                        break;
                    case 'instruction':
                        fileurl = pdfFetcher.getCDNURL()+'01_Preface/04_Instruction_V7.1.pdf';
                        break;
                    case 'manual':
                        fileurl = pdfFetcher.getCDNURL()+'01_Preface/05_User%20Manual_V7.1.pdf';
                        break;
                    case 'toc':
                        fileurl = pdfFetcher.getCDNURL()+'01_Preface/06_Table%20of%20Contents_V5.pdf';
                        break;
                    case 'copyright':
                        fileurl = pdfFetcher.getCDNURL()+'01_Preface/02_copyright_page_V6.pdf';
                        break;
                    case 'gospel-intro':
                        fileurl = pdfFetcher.getCDNURL()+'02_eCalBible_Introduction_pdf/00_4_Gospel_Introduction_II_V4.pdf';
                        break;
                    case 'map-time-patriarchs':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%201_Time%20of%20the%20Patriarchs_crop.pdf';
                        break;
                    case 'map-route-exodus':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%202_Possible%20Route%20of%20the%20Exodus.pdf';
                        break;
                    case 'map-division-canaan':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%203_Division%20of%20Canaan.pdf';
                        break;
                    case 'map-time-judges':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%204_Time%20of%20the%20Judges.pdf';
                        break;
                    case 'map-united-israel':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%205_United%20Israelite%20Kingdom.pdf';
                        break;
                    case 'map-babylonian-empire':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%206a_The%20Babylonian%20Empire.pdf';
                        break;
                    case 'map-persian-empire':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%206b_The%20Medo-Persian%20Empire.pdf';
                        break;
                    case 'map-greek-empire':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%207a_The%20Greek%20Empire.pdf';
                        break;
                    case 'map-roman-empire':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%207b_The%20Roman%20Empire.pdf';
                        break;
                    case 'map-palestine-jesus-time':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%208_Palestine%20in%20the%20Time%20of%20Jesus.pdf';
                        break;
                    case 'map-jerusalem-new-testament':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%209_Jerusalem%20in%20the%20New%20Testament%20Times.pdf';
                        break;
                    case 'map-paul-first-mission':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%2010%20Paul%27s%20First%20Missionary%20Journey.pdf';
                        break;
                    case 'map-paul-second-mission':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%2011%20Paul%27s%20Second%20Missionary%20Journey.pdf';
                        break;
                    case 'map-paul-third-mission':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%2012%20Paul%27s%20Third%20Missionary%20Journey%202.pdf';
                        break;
                    case 'map-paul-rome-journey':
                        fileurl = pdfFetcher.getCDNURL()+'05_Maps_pdf_crop/Map%2013_Paul%27s%20Journey%20to%20Rome.pdf';
                        break;
                    case 'analytical-layout':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/01_Study%20II%20Chinese%20Analytical%20Layout_V4.pdf';
                        break;
                    case 'hermaneutics':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/02_Study%20II%20Hermeneutic_V4.pdf';
                        break;
                    case 'preaching':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/03_Study%20II%20Preaching_V4.pdf';
                        break;
                    case 'covenants':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/04_Study%20II%20Covenants_V4.pdf';
                        break;
                    case 'doctrines':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/05_Study%20II%20Doctrines_V4.pdf';
                        break;
                    case 'messiah-prophecies':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/06_Study%20II%20Prophesy%20of%20Messiah_V4.pdf';
                        break;
                    case 'life-of-christ':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/07_Study%20II%20Life%20of%20Christ_V4.pdf';
                        break;
                    case 'miracles-of-jesus':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/08_Study%20II%20Miracles_V4.pdf';
                        break;
                    case 'parables-of-jesus':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/09_Study%20II%20Parables_V4.pdf';
                        break;
                    case 'bible-theme-chart':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/10_Bible%20Major%20Theme%20Chart_V4_crop.pdf';
                        break;
                    case 'festivals':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/11_Israel%20Festivals_V4._crop.pdf';
                        break;
                    case 'jewish-calendar':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/12_Jewish%20Calendar_Biblical%20Times_V4_crop.pdf';
                        break;
                    case 'kings-israel':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/13_Kings%20of%20Israle%20and%20Juday_page%201-2%20_V4_crop.pdf';
                        break;
                    case 'israel-captivity':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/14_Israel%20Capticity%20and%20Return%20Date_V4_crop.pdf';
                        break;
                    case 'paul-missionary-journey':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/15_Paul%20Missionary%20Journeys_V4_crop.pdf';
                        break;
                    case 'daniel-structure':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/16_Structure%20of%20Book%20of%20Daniel_V4_crop.pdf';
                        break;
                    case 'end-time-events':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/17_End%20Time%20Events_V4_crop.pdf';
                        break;
                    case 'scripture-year-reading':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/18_Scrupture%20Reading%20in%20a%20year_V4_crop.pdf';
                        break;
                    case 'scripture-measurements':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/19_Biblical%20Measurements_V4_crop.pdf';
                        break;
                    case 'difficult-words':
                        fileurl = pdfFetcher.getCDNURL()+'04_Appendix_V4_pdf/20_Difficult%20Words%20Pronounciation_V4.1.pdf';
                        break;
                    default:
                        return null;
                }
            }
        }
        return fileurl;
    }
}
export default pdfFetcher;