import React from 'react';
import PDF from 'react-pdf-js-infinite';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import pdfFetcher from "../../modules/pdf-fetcher";
import { useHistory } from "react-router-dom";
import {cdnurl} from "../../api/storage";
import './pdfviewer.scss';

const PdfViewer = ({filecode}) => {
    const screenSize = useWindowDimensions();
    const pageWidth = Math.min(screenSize.width * 0.9, 830); //90vw  max-width: 830px
    const fileURL = pdfFetcher.getFileURLFromCode(filecode);

    let textScale, leftMargin;

    if (screenSize.width > 826) {
        textScale = 2.5;
        leftMargin = 'auto';
    } else {
        textScale = 1.67;
        leftMargin = '-10px';
    }

    const history = useHistory();

    const goBack = () => {
      history.goBack();
    };

    if (fileURL !== null) {
        return (
            <div className="page-container">
                <div className="back-arrow">
                    <img src={cdnurl + 'assets/images/back-arrow.png'} onClick={goBack} alt="Go Back"/>
                </div>
                <div className="pdf-container" style={{width: pageWidth + 'px', marginLeft: leftMargin}}>
                    <PDF file={fileURL} scale={textScale} loading={<div><img alt="Loading..." src={cdnurl + 'assets/images/loading-blue.gif'}/></div>}/>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <h4>Error: Unable to load content...</h4>
            </div>
        );
    }
}
export default PdfViewer;