/* Add action imports in this file to combine into global actions*/
export const setActivated = (payload) => ({
    type: 'SET_ACTIVATED',
    payload
});
export const setCheckingActivation = (payload) => ({
    type: 'SET_CHECKING_ACTIVATION',
    payload
});
export const setActivationModal = (payload) => ({
    type: 'SET_ACTIVATION_MODAL',
    payload
});
export const setActivationMessage = (payload) => ({
    type: 'SET_ACTIVATION_MSG',
    payload
});