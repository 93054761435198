import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from "@material-ui/core/DialogActions";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
    modalContent: {
        paddingBottom: theme.spacing(1),
    },
});

class UpdateModal extends React.Component {
    render() {
        const {classes, updaterOpen, onUpdaterDoUpdate, onUpdaterClose} = this.props;
        return (
            <Dialog
                open={updaterOpen}
                onClose={onUpdaterClose}
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">有更新</DialogTitle>
                <DialogContent className={classes.modalContent}>
                    <Typography variant="h6" className={classes.title}>
                        排版聖經軟體有更新. 你必須關閉及重新開啟軟體, 來完成更新.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onUpdaterDoUpdate}>
                        現在更新
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default withMobileDialog()(withStyles(styles)(UpdateModal));