import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import SettingManager from '../SettingManager';

import { languageGroups } from '../config';
import {storage} from "../api/storage";

const styles = theme => ({
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  modalContent: {
    paddingBottom: theme.spacing(1),
  },
  switchOption: {
    marginTop: -theme.spacing(1/2),
    marginBottom: -theme.spacing(1/2),
  },
});

class SettingModal extends Component {
  constructor(props) {
    super(props);
    const { visibleLanguages } = SettingManager.getSetting();

    const visibleCodes = visibleLanguages.reduce((acc, cur) => { acc.add(cur.code); return acc }, new Set());
    const language = Array(languageGroups.length).fill(true);
    languageGroups.forEach((lg, i) => {
      if (lg.codes.filter(code => !visibleCodes.has(code)).length !== 0)
        language[i] = false;
    });
    this.state = { language, outlines: SettingManager.showOutline(), showconfirm: false };
  }

  handleChange = i => (evt, v) => {
    const { outlines } = this.state;

    this.setState({ outlines: !outlines });
    SettingManager.saveSetting(!outlines);
/*    const unvisibleLanguages = {};
    languageGroups.forEach((lg, i) => {
      if (language[i] === false){
        for (const code of lg.codes)
          unvisibleLanguages[code] = true;
      }
    });

    SettingManager.saveSetting(unvisibleLanguages);
    this.props.onSettingChange();*/
  };

  deactivateConfirm = () => {
    this.setState({showconfirm: true});
  }
  doDeactivate = () => {
    storage.deActivate();
  }
  cancelDeactivate = () => {
    this.setState({showconfirm: false});
  }
  onClose = () => {
    this.setState({showconfirm: false}, this.props.onClose);
  }

  render() {
    const { classes, fullScreen } = this.props;
    const { showconfirm } = this.state;
    return (
      <Dialog
        // 모바일 환경에서 fullScreen으로 하려면 아래 주석을 해제
        // fullScreen={fullScreen}
        fullScreen={false && fullScreen}
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth="xs"
        fullWidth={true}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">設定</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.gutterBottom}>顯示主題和描述</FormLabel>
            <FormGroup>
              <FormControlLabel
                  classes={{ root: classes.switchOption }}
                  control={
                    <Switch
                        checked={this.state.outlines === true}
                        onChange={this.handleChange()}
                        color="primary"
                    />
                  }
                  label="顯示"
              />
            </FormGroup>

            <FormLabel component="legend" className={classes.gutterBottom}>&nbsp;</FormLabel>
            <FormGroup style={{border: '1px solid rgb(177 177 177)', padding: '25px'}}>
              <p style={{fontSize: '16px'}}>eCalBible v{storage.getAppVersion()}</p>
              {!showconfirm ?
                  <FormControlLabel
                      control={
                        <Button onClick={this.deactivateConfirm} variant="outlined" color="secondary" style={{marginRight: '10px'}}>刪除程式</Button>
                      }
                      label="在這器材上刪除程式"
                  />
                  :
                  <Fragment>
                    <strong>你確定要在這器材上刪除 eCalBible 應用程式嗎?</strong><br/> 你若買了新的器材 (手機/平版/電腦), 要把 eCalBible 應用程式安裝在新的器材上, 並確定將不會繼續在這原有器材上使用 eCalBible 應用程式,
                    你就可以刪除 eCalBible 應用程式。
                    <span style={{display: 'inline-flex', marginTop: '10px', marginLeft: '50px'}}>
                      <Button onClick={this.doDeactivate} variant="contained" color="secondary" style={{width: '40%', marginRight: '10px'}}>刪除程式</Button><Button onClick={this.cancelDeactivate} variant="contained" color="primary" style={{width: '40%'}}>取消</Button>
                    </span>
                  </Fragment>
              }
{/*              {languageGroups.map((obj, i) => (
                <FormControlLabel
                  key={i}
                  classes={{ root: classes.switchOption }}
                  control={
                    <Switch
                      checked={this.state.language[i]}
                      onChange={this.handleChange(i)}
                      color="primary"
                    />
                  }
                  label={obj.label}
                />
              ))}*/}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SettingModal.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,

  onSettingChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default withMobileDialog()(withStyles(styles)(SettingModal));
