import { languages } from './config';

class SettingManager {
  constructor() {
    this.loadSetting();
  }

  loadSetting = () => {
    const unvisibleLanguages = JSON.parse(localStorage.getItem('unvisibleLanguages') || '{}');
    this.visibleLanguages = languages.filter(l => !unvisibleLanguages.hasOwnProperty(l.code));

  };

  getSetting = () => ({
    visibleLanguages: this.visibleLanguages,
  });

  saveSetting = (showoutline/*unvisibleLanguages*/) => {
    //localStorage.setItem('unvisibleLanguages', JSON.stringify(unvisibleLanguages));
    localStorage.setItem('showOutline', showoutline);
    this.loadSetting();
  };

  showOutline = () => {
    let showoutline = localStorage.getItem('showOutline');
    if (showoutline === null)  {
      localStorage.setItem('showOutline', true);
      showoutline = 'true';
    }
    return showoutline === 'true';
  };
}

export default new SettingManager();
