import React from 'react';

const moduleName = {
    getName: () => {
        const pdfModules = ['preface','gospel-intro','instruction','manual','table-of-contents','maps','appendix','copyright'];

        let modulename = 'bible';
        const urlarr = document.location.href.split('/');
        if (pdfModules.includes(urlarr[3]) || urlarr[4] === 'chart' || urlarr[4] === 'outline') {
            modulename = 'pdf';
        }
        return modulename
    }
}
export default moduleName;