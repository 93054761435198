import React, {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import FingerPrint from "./DeviceFingerprint";
import {apiurl, storage, cdnurl} from "../api/storage";
import { useDispatch, useSelector } from 'react-redux'
import axios from "axios";
import {setActivationModal, setCheckingActivation} from "../api/redux/actions";

function getModalStyle() {
    const top = 40;
    //const left = 50;

    return {
        top: `${top}%`,
        margin:'auto'
        /*left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,*/
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        resize: true,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ActivationModal() {
    const classes = useStyles();
    const dispatch = useDispatch();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [key, setKey] = React.useState('');
    const [error, setError] = React.useState('');
    const open = useSelector((state) => state.activation_modal);
    const checkingActivation = useSelector((state) => state.checking_activation);
    const message = useSelector((state) => state.activation_message)

    let openModal = open;

    if (checkingActivation) {
        openModal = false;
    }

    useEffect(() => {
        if (message !== null) {
            setError(message);
        }
    }, [message]);

    const setOpen = (bool) => {
        dispatch(setActivationModal(bool));
        dispatch(setCheckingActivation(false));
    }

    const resetScale = () => {
        // Safari 3.0+  Don't allow zoom during activation
        const viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'minimum-scale=1, maximum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no');
    };

    const handleKeyInput = (e) => {
        setKey(e.target.value);
    };

    const handleActivation = () => {
        if (key.length === 68 && key.includes('CBK-')) {
            const postdata = {
                akey: key,
                did: FingerPrint(window, window.screen, window.navigator),
                device: navigator.userAgent
            }
            axios.post(apiurl + 'calbible/activate', postdata).then((res) => {
                if (typeof res.data.success !== 'undefined' && res.data.success) {
                    if (!storage.setActivation(postdata.did, key, res.data.activation_id, res.data.email)) {
                        setError('輸入的激活密鑰出現問題, 請聯絡聖經信息學院 (BibleExposition02@gmail.com)');
                    } else {
                        window.location.reload();
                    }

                } else {
                   storage.clearActivation(); 
                   setError('An error occurred while trying to activate the app.');
                }   
            }).catch((error) => {
                setError(error.response.data.message);
            })
        } else {
            setError('激活密鑰不對, 請確認所輸入的激活密鑰是否正確');
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Modal
                open={openModal}
                /*onClose={handleClose}*/
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">eCalBible 激活</h2>
                    <Fragment>
                        <p id="simple-modal-description">
                            需要在這網站購買聖經分析排版本聖經電子版 <a href="https://www.bibleexposition.org/online-store.html" target="_blank">https://www.bibleexposition.org/online-store.html</a>
                        </p>
                        <p>購買完成後, 請將電郵給你的激活密鑰輸入:</p>
                        {error.length ?
                            <p style={{color: "red"}}>{error}</p>
                            :
                            ''
                        }
                        <label htmlFor="activation-key"><strong>激活密鑰:</strong></label>
                        <input id="activation-key" type="text" value={key} onInput={handleKeyInput} onClick={resetScale} style={{width: '100%', height: '30px'}}/>
                        <Button onClick={handleActivation} variant="contained" color="primary" style={{marginTop: '10px'}}>啟用</Button>
                    </Fragment>

                </div>
            </Modal>
        </Fragment>
    );
}
