import React from 'react';
import ListView from "./listview/ListView";

const Appendix = () => {

    const items = [
        {name: '經文分析排版讀經法', link: '/analytical-layout'},
        {name: '解釋聖經的基本原則', link: '/hermaneutics'},
        {name: '講道要義', link: '/preaching'},
        {name: '聖經中的約', link: '/covenants'},
        {name: '聖經中的教義', link: '/doctrines'},
        {name: '彌賽亞的預言及應驗', link: '/messiah-prophecies'},
        {name: '耶穌基督生平合參', link: '/life-of-christ'},
        {name: '耶穌基督所行的神蹟', link: '/miracles-of-jesus'},
        {name: '耶穌基督所講的比喻', link: '/parables-of-jesus'},
        {name: '聖經主題結構表', link: '/bible-theme-chart'},
        {name: '以色列的節期', link: '/festivals'},
        {name: '聖經中的曆法', link: '/jewish-calendar'},
        {name: '以色列猶大列王對照表', link: '/kings-israel'},
        {name: '以色列被擄回歸年日表', link: '/israel-captivity'},
        {name: '保羅宣教旅程和著作圖表', link: '/paul-missionary-journey'},
        {name: '但以理書結構圖表', link: '/daniel-structure'},
        {name: '主再來末世大事次序', link: '/end-time-events'},
        {name: '一年讀經計劃', link: '/scripture-year-reading'},
        {name: '聖經中的度量衡', link: '/scripture-measurements'},
        {name: '聖經難字讀音表', link: '/difficult-words'}
    ];

    return (
        <ListView title="附錄" items={items} numbers={true}/>
    )
};
export default Appendix;