import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './burgermenu.scss';

export default function BurgerMenu({open, setOpen}) {
    return (
        <Menu pageWrapId={'page-wrap'}
              outerContainerId={'outer-container'}
        >
            <a id="home" className="menu-item" href="/">聖經</a>
            <a id="copyright" className="menu-item" href="/copyright">版權</a>
            <a id="preface" className="menu-item" href="/preface">序言</a>
            <a id="instruction" className="menu-item" href="/instruction">特點</a>
            <a id="manual" className="menu-item" href="/manual">使用說明</a>
            <a id="toc" className="menu-item" href="/table-of-contents">目錄</a>
            <a id="gospel-intro" className="menu-item" href="/gospel-intro">四福音書簡介</a>
            <a id="appendix" className="menu-item" href="/appendix">附錄</a>
            <a id="maps" className="menu-item" href="/maps">地圖</a>
        </Menu>
    );
}