import React from 'react';
import ListView from "./listview/ListView";

const Maps = () => {
    const items = [
        {name: '列祖時代', link: '/map-time-patriarchs'},
        {name: '出埃及進迦南的可能路線', link: '/map-route-exodus'},
        {name: '迦南分地', link: '/map-division-canaan'},
        {name: '士師時代', link: '/map-time-judges'},
        {name: '聯合以色列王國', link: '/map-united-israel'},
        {name: '巴比倫帝國', link: '/map-babylonian-empire', number: '6a'},
        {name: '瑪代波斯帝國', link: '/map-persian-empire', number: '6b'},
        {name: '希臘帝國', link: '/map-greek-empire', number: '7a'},
        {name: '羅馬帝國', link: '/map-roman-empire', number: '7b', setcnt: 7},
        {name: '耶穌時代的巴勒斯坦', link: '/map-palestine-jesus-time'},
        {name: '新約時代的耶路撒冷', link: '/map-jerusalem-new-testament'},
        {name: '保羅第一次佈道之旅', link: '/map-paul-first-mission'},
        {name: '保羅第二次佈道之旅', link: '/map-paul-second-mission'},
        {name: '保羅第三次佈道之旅', link: '/map-paul-third-mission'},
        {name: '保羅往羅馬的行程', link: '/map-paul-rome-journey'}
    ];

    return (
        <ListView title="地圖" items={items} numbers={true}/>
    )
};
export default Maps;