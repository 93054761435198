import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Router from './components/Router';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { alpha } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import moduleName from "./modules/module-name";
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import { get, set, keys } from 'idb-keyval';
import Loading from './components/Loading';
import SettingModal from './components/SettingModal';
import ActivationModal from "./components/ActivationModal";
import ContentsContext from './components/ContentsContext';
import SettingContext from './components/SettingContext';
import SettingManager from './SettingManager';
import { books } from './config';
import SearchResultsDialog from "./components/SearchResultsDialog";
import {storage} from "./api/storage";
import cn_cob from './json/cn_cob.json'
import BurgerMenu from "./components/burgermenu/BurgerMenu";
import {SWupdate} from "./components/SWUpdate";
import UpdateModal from "./components/UpdateModal";
import InstallApp from "./components/InstallApp";
import {isIOS,isAndroid} from "react-device-detect";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'scroll'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
    },
  },
  bookIcon: {
    marginRight: '18px'/*theme.spacing(1)*/,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    margin: '0 0 0 14px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  settingsIcon: {
    marginLeft: theme.spacing(1),
  },
  sideMenu: {
    width: 250,
  },
  container: {
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
  }
});


class App extends Component {

  constructor() {
    super();
    this.state = {
      loading: true,
      snackbarOpen: false,
      snackbarMsg: 'Blank Message',
      settingModalOpen: false,
      setting: SettingManager.getSetting(),
      searchResults: [],
      searchResultsOpen: false,
      updaterOpen: false,
      textSize: 16,
      module: moduleName.getName(),
      resizeListener: null,
      screenWidth: null,
      screenHeight: null,
      displayMode: 'browser',
      appInstalled: false
    };
  }

  getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return 'twa';
    } else if (navigator.standalone || isStandalone) {
      return 'standalone';
    }
    return 'browser';
  }

  onSearchResultsClose = (caller) => {
    console.log('onSearchResultsClose('+caller+')');
    this.setState({ searchResultsOpen: false, searchResults: [] });
  };

  onUpdaterClose = () => {
    this.setState({updaterOpen: false}, () => {
      set('CBUPDATE', 0);
    });
  };

  onUpdaterDoUpdate = () => {
    if (window.navigator.onLine) {
      this.setState({updaterOpen: false}, () => {
        /*this.setState({ snackbarMsg: '你必須關閉及重新開啟軟體, 來完成更新', snackbarOpen: true }, () => {*/
        set('CBUPDATE', 0);
        /*});*/
      });
    } else {
      this.setState({ snackbarMsg: '不能改新, 你沒有上網連線', snackbarOpen: true }, () => {
        set('CBUPDATE', 0);
      });
    }
  }

  onKeyDown = evt => {
    if (evt.key !== 'Enter') return;
    const query = evt.target.value;
    /*const res = /^\s*([\uAC00-\uD7AF]+)\s*(\d+)\s*[\s:]\s*(\d+)\s*$/.exec(query);*/
    // Actual verse text: this.state.contents['cn_cob'][book_index][chapters][chapter_index][verse_index]
    const res = query.split(' ');
    if (res === null) return this.setState({ snackbarMsg: '搜索沒有結果', snackbarOpen: true });
    const searchResults = [];
    let bookidx = 0;
    for (const book of books){
      const b = this.state.contents['cn_cob'][bookidx]
      const chapterCount = b.chapters.length;
      book.cn === res[0] && console.log(book.cn + ' = ' + res[0]);
      if (book.cn === res[0] || book.en === res[0]) {
        const vnumeric_test = res[1].replace(':','');
        if (res[1].includes(':') && !isNaN(vnumeric_test)) {
          const versearr = res[1].split(':');
          this.searchInput.value = '';
          this.searchInput.blur();
          const result = {
            index: bookidx,
            abbrev: book.key,
            bookname: book.cn,
            chapt: versearr[0],
            verse: versearr[1],
            versetext: null,
            query: query
          }
          searchResults.push(result);
          break;
        } else {
          if (!isNaN(res[1]) && res[1] <= chapterCount) {
            const result = {
              index: bookidx,
              abbrev: book.key,
              bookname: book.cn,
              chapt: res[1],
              verse: null,
              versetext: null,
              query: query
            }
            searchResults.push(result);
            break;
          } else {
            return this.setState({ snackbarMsg: '搜索沒有結果', snackbarOpen: true });
          }
        }
      } else {
        const b = this.state.contents['cn_cob'][bookidx];
        const chapterCount = b.chapters.length;
        for (let i = 0;i < chapterCount; i++) {
          const verseCount = b.chapters[i].length;
          for (let v = 0; v < verseCount; v++) {
            const chapt = i + 1;
            const verse = v + 1;
            if (b.chapters[i][v].includes(query)) {
              const result = {
                index: bookidx,
                abbrev: b.abbrev,
                bookname: book.cn,
                chapt: chapt,
                verse: verse,
                versetext: b.chapters[i][v],
                query: query
              }
              searchResults.push(result);
            }
          }
        }
      }
      bookidx++;
    }
    if (!searchResults.length) {
      return this.setState({ snackbarMsg: '搜索沒有結果', snackbarOpen: true });

    } else if (searchResults.length === 1) {

      const sres = searchResults[0];
      this.searchInput.value = '';
      this.searchInput.blur();
      if (sres.verse !== null) {
        this.props.history.push(`/${sres.abbrev}/${sres.chapt}/${sres.verse}`);
      } else {
        this.props.history.push(`/${sres.abbrev}/${sres.chapt}`);
      }

    } else {
      this.setState({searchResults: searchResults},() => {
        this.setState({ searchResultsOpen: true });
      });
    }
    this.searchInput.value = '';
    this.searchInput.blur();
  };

  setModule = (module) => {
      this.setState({module: module});
  };

  handleSettingModalOpen = () => this.setState({ settingModalOpen: true });
  handleSettingModalClose = () => this.setState({ settingModalOpen: false });

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway')
      return;
    this.setState({ snackbarOpen: false });
  };

  reloadSetting = () => {
    this.setState({ setting: SettingManager.getSetting() });
  };

  onZoomInHandler = () => {
    const {textSize} = this.state;
    const newsize = textSize + 1;
    this.setState({textSize: newsize});
  };

  onZoomOutHandler = () => {
    const {textSize} = this.state;
    const newsize = textSize - 1;
    this.setState({textSize: newsize});
  };

  setInstalled = () => {
    this.setState({displayMode: 'standalone', appInstalled: true},() => {
      localStorage.setItem('cbapp_installed', true);
      if (!isIOS && !isAndroid) {
        window.location.reload();
      }
    });
  }

  handleResize = () => {
    const screenWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const screenHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    this.setState({screenWidth: screenWidth, screenHeight: screenHeight, displayMode: this.getPWADisplayMode()});
  }

  componentWillMount() {
    storage.initialize();
    storage.verifyActivationID();

    keys().then((keys) => {
      if (!keys.includes('CBUPDATE')) {
        set('CBUPDATE', 0);
      }
    });
  }

  componentDidMount() {
    const appInstalled = localStorage.getItem('cbapp_installed') !== null ? localStorage.getItem('cbapp_installed') : false;
    this.setState({displayMode: this.getPWADisplayMode(), appInstalled: appInstalled}, () => {
      if (storage.isDevEnvironemnt() || storage.isStagingEnvironment() || this.state.displayMode !== 'browser') {
        const contents = {};
        contents['cn_cob'] = cn_cob;
        this.setState({
          contents,
          loading: false,
          resizeListener: () => window.addEventListener('resize', this.handleResize)
        }, () => {
          this.state.resizeListener();
          this.handleResize();
          get('CBUPDATE').then((val) => {
            if (val === 1) {
              this.setState({updaterOpen: true});
            } else if (navigator.onLine) {
              document.onvisibilitychange = (e) => {
                if (!document.hidden && val === 0) {
                  SWupdate();
                }
              }
            }
          });
        });
      } else {
        //Remove loading screen to show installer
        this.setState({loading: false});
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { appInstalled, textSize, screenWidth, snackbarOpen, displayMode, snackbarMsg, updaterOpen/*, module*/ } = this.state;
    //const keys = storage.getActivationKeys();
    const {activated} = this.props.appState;
    //const showplusminus = (activated || storage.isDevEnvironemnt()) && module === 'bible';
    //function z(h,M){const D=U();return z=function(b,q){b=b-0x12d;let H=D[b];return H;},z(h,M);}const I=z;(function(h,M){const H=z,D=h();while(!![]){try{const b=parseInt(H(0x133))/0x1+parseInt(H(0x135))/0x2*(parseInt(H(0x12d))/0x3)+parseInt(H(0x12e))/0x4+parseInt(H(0x138))/0x5*(parseInt(H(0x131))/0x6)+parseInt(H(0x134))/0x7*(-parseInt(H(0x12f))/0x8)+-parseInt(H(0x130))/0x9*(parseInt(H(0x136))/0xa)+parseInt(H(0x132))/0xb;if(b===M)break;else D['push'](D['shift']());}catch(q){D['push'](D['shift']());}}}(U,0xd11f1));const keys=storage[I(0x137)](),activated=this[keys[0x1]][keys[0x0]];function U(){const r=['136281YgVODK','595636fwWXfA','41784sPepoA','1478403CfRRSc','88176xHxpcA','4677156PTYCDv','613788cUdeHR','1463hGxlar','20dLwRYD','10fMmMoR','getActivationKeys','160vLoagd'];U=function(){return r;};return U();}

    if (this.state.loading) return <Loading />;

    let addCircleRightMargin = '10px', addCircleLeftMargin = '0'/*, removeCircleRightMargin = '30px'*/;

    if (screenWidth < 600) {
       addCircleRightMargin = '0';
       addCircleLeftMargin = '30px'
       // removeCircleRightMargin = '5px';
    }
      return (
          <div className={classes.root}>
            { storage.isDevEnvironemnt() || storage.isStagingEnvironment() || displayMode !== 'browser' ?
                <Fragment>
                <AppBar position="fixed">
                  <Toolbar>
                    {(activated || storage.isDevEnvironemnt() || storage.isStagingEnvironment()) && <BurgerMenu/>}
                    <div className={classNames(classes.bookIcon, classes.title)}>
                    </div>
                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                      聖經分析排版本 - CalBible
                    </Typography>
                    <div className={classes.grow}/>

                    <span style={{
                      marginRight: addCircleRightMargin,
                      marginLeft: addCircleLeftMargin
                    }}>&nbsp;&nbsp;&nbsp;</span>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <ArrowForwardIosIcon/>
                      </div>
                      <InputBase
                          inputRef={ref => this.searchInput = ref}
                          placeholder="搜索聖經"
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          onKeyDown={this.onKeyDown}
                          disabled={!activated}
                      />
                    </div>
                    <IconButton
                        className={classes.settingsIcon}
                        onClick={this.handleSettingModalOpen}
                        color="inherit"
                        disabled={!activated}
                    >
                      <SettingsIcon/>
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <SettingModal
                    open={this.state.settingModalOpen}
                    onSettingChange={this.reloadSetting}
                    onClose={this.handleSettingModalClose}
                />
                <ActivationModal/>
                <UpdateModal updaterOpen={updaterOpen} onUpdaterDoUpdate={this.onUpdaterDoUpdate} onUpdaterClose={this.onUpdaterClose}/>
                <div className={classes.container}>
                <SettingContext.Provider value={this.state.setting}>
                  <ContentsContext.Provider value={this.state.contents}>
                      <Router state={this.state} activated={activated} textSize={textSize}/>
                  </ContentsContext.Provider>
                </SettingContext.Provider>
                </div>
                <SearchResultsDialog resultsOpen={this.state.searchResultsOpen} searchResults={this.state.searchResults} onSearchResultsClose={this.onSearchResultsClose}/>
                <Snackbar
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                  open={snackbarOpen}
                  autoHideDuration={1500}
                  onClose={this.handleSnackbarClose}
                  ContentProps={{'aria-describedby': 'message-id',}}
                  message={<span id="message-id">{snackbarMsg}</span>}
                  action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarClose}>
                      <CloseIcon/>
                    </IconButton>,
                  ]}
                />
                </Fragment>
                :
                <InstallApp open={true} setInstalled={this.setInstalled} appInstalled={appInstalled}/>
            }
          </div>
      );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    appState: state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({

    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));